import { Box, Card, CardActions, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { PaginatedListState } from 'component/hooks/usePaginatedListState';
import { useTranslation } from 'react-i18next';
import { Fragment, ReactNode } from 'react';
import { PaginationControls } from 'component/new_design/base/PaginationControls';
import { useDebouncedInputProps } from 'component/hooks/useDebouncedInput';
import { SearchInput } from 'component/new_design/base/SearchInput';
import { usePaginatedListLoadingState } from 'component/hooks/usePaginatedListLoadingState';

interface Props<T> {
  readonly headerComponent?: ReactNode;
  readonly title: ReactNode;
  readonly description?: ReactNode;
  readonly isLoading: boolean;
  readonly data: T[];
  readonly totalRowCount: number;
  readonly state: PaginatedListState;
  readonly enableSearch?: boolean;
  readonly enablePagination?: boolean;
  readonly children?: ReactNode;
  readonly searchPlaceholder?: string;
  readonly rowIdKey?: keyof T;
  readonly emptyState: ReactNode;
  readonly renderItem: (item: T) => ReactNode;
  readonly renderItemLoader: () => ReactNode;
  readonly rowGap?: number;
  readonly columnGap?: number;
}

const COLUMN_COUNT = 3;
const LOADING_ITEM_COUNT = 12;

export const GRID_PER_PAGE_OPTIONS = [12, 48, 96] as const;

const GridRowSeparator = () => (
  <Box
    sx={{
      gridColumn: '1 / -1',
      height: '1px',
      backgroundColor: 'greys.100',
      '&:last-of-type': {
        display: 'none',
      },
    }}
  />
);

export const Grid = <T extends Record<string, unknown>>({
  title,
  description,
  isLoading,
  data,
  totalRowCount,
  state,
  enableSearch = false,
  enablePagination = true,
  children,
  searchPlaceholder = '',
  rowIdKey = 'id',
  emptyState,
  renderItem,
  renderItemLoader,
  rowGap = 4,
  columnGap = 4,
}: Props<T>) => {
  const { t } = useTranslation();

  const { params, setSearch } = state;

  const searchInputProps = useDebouncedInputProps(params.search, setSearch);

  const loadingState = usePaginatedListLoadingState(!!data.length, isLoading, params);

  return (
    <Card>
      <Stack direction="row" alignItems="center">
        <CardHeader
          title={title}
          subheader={description}
          action={
            loadingState === 'noData' ? null : (
              <>
                {enableSearch ? (
                  <SearchInput {...searchInputProps} placeholder={searchPlaceholder} />
                ) : null}
                {children}
              </>
            )
          }
        />
      </Stack>

      <CardContent sx={loadingState === 'noData' ? { height: '300px' } : null}>
        {loadingState === 'noData' ? emptyState : null}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(${COLUMN_COUNT}, 1fr)`,
            columnGap: theme => theme.spacing(columnGap),
            rowGap: theme => theme.spacing(rowGap),
          }}
        >
          {loadingState === 'loading'
            ? Array.from({ length: LOADING_ITEM_COUNT }, () => null).map((_, index) => (
                <Fragment key={index}>{renderItemLoader()}</Fragment>
              ))
            : null}
          {loadingState === 'hasData'
            ? data.map((item, index) => (
                <Fragment key={`${item[rowIdKey]}`}>
                  {renderItem(item)}
                  {(index + 1) % COLUMN_COUNT === 0 ? <GridRowSeparator /> : null}
                </Fragment>
              ))
            : null}
        </Box>
        {loadingState == 'noResults' ? (
          <Typography my={10} textAlign="center">
            {t('no_results_found')}
          </Typography>
        ) : null}
      </CardContent>

      {enablePagination ? (
        <CardActions>
          <PaginationControls state={state} totalRowCount={totalRowCount} />
        </CardActions>
      ) : null}
    </Card>
  );
};
