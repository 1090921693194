import { Close } from '@mui/icons-material';
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FilledInput,
  IconButton,
  InputAdornment,
  Stack,
} from '@mui/material';
import { useDialogProps } from 'component/hooks/useDialogProps';
import { ReactNode, useId, useState } from 'react';
import { ReportingList } from './ReportingList';
import { useTranslation } from 'react-i18next';
import SearchBig from 'component/new_design/icons/SearchBig.svg?react';
import { ReportingListItem } from './ReportingOverview';

interface ReportingListDialogProps {
  readonly title: string;
  readonly data: ReportingListItem[];
  readonly renderLabel?: (item: ReportingListItem) => ReactNode;
  readonly onClose?: () => unknown;
}

export const ReportingListDialog = ({
  title,
  data,
  renderLabel,
  onClose,
}: ReportingListDialogProps) => {
  const { t } = useTranslation();
  const sdkDialogProps = useDialogProps(onClose, false);
  const titleId = useId();

  const [search, setSearch] = useState('');

  const filteredData = data.filter(({ formattedMetric }) =>
    formattedMetric.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Dialog fullWidth maxWidth="md" open aria-labelledby={titleId} {...sdkDialogProps}>
      <DialogTitle id={titleId}>
        <Stack direction="row" alignItems="center" gap={2}>
          {title}
          <Chip variant="badge" color="secondary" label={data.length} />
        </Stack>
        <Stack direction="row" gap={4}>
          <FilledInput
            startAdornment={
              <InputAdornment position="start">
                <SearchBig />
              </InputAdornment>
            }
            placeholder={t('search')}
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <IconButton onClick={onClose} edge="end">
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ height: '60vh', overflowY: 'auto', p: 1 }}>
        <ReportingList data={filteredData} renderLabel={renderLabel} variant="dialog" />
      </DialogContent>
    </Dialog>
  );
};
