import { FormControl, FormHelperText, FilledInput, FilledInputProps } from '@mui/material';
import { ChangeEventHandler, ReactNode, useCallback, useId } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';
import { Label } from './Label';

interface TextFieldProps extends Omit<FilledInputProps, 'onChange' | 'onBlur'> {
  readonly name: string;
  readonly label: ReactNode;
  readonly rules?: RegisterOptions;
  readonly helperText?: ReactNode;
  readonly formatValue?: (value: string) => string;
}

export const TextField = ({ name, label, rules, formatValue, ...rest }: TextFieldProps) => {
  const id = useId();
  const {
    field: { ref, onChange, ...field },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({
    name,
    rules,
    disabled: rest.disabled,
    defaultValue: rest.defaultValue,
  });

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    event => {
      if (formatValue) {
        event.target.value = formatValue(event.target.value);
      }
      onChange(event);
    },
    []
  );

  const helperText = error?.message || rest.helperText;

  return (
    <FormControl>
      <Label htmlFor={id}>{label}</Label>
      <FilledInput
        id={id}
        inputRef={ref}
        {...rest}
        {...field}
        fullWidth
        onChange={handleChange}
        disabled={field.disabled || isSubmitting}
      />
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};
