import { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import DeleteDustbin from 'component/new_design/icons/DeleteDustbin.svg?react';
import { useForm } from 'react-hook-form';
import { usePurgeAllCache, usePurgeSpecificPaths } from 'api/site';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { FormDialog } from 'component/new_design/base/dialogs/FormDialog';
import { RadioField } from 'component/new_design/base/forms/RadioField';
import { useParams } from 'react-router-dom';
import { TextField } from 'component/new_design/base/forms/TextField';

interface ClearCacheData {
  type: 'all' | 'paths';
  paths: string;
}

export const ClearCacheDialog = () => {
  const { siteId } = useParams<{ siteId: string }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);

  const methods = useForm<ClearCacheData>({
    defaultValues: {
      type: 'all',
      paths: '',
    },
    resolver: values => ({
      values,
      errors: values.type === 'paths' && !values.paths ? { paths: 'required' } : {},
    }),
  });

  const type = methods.watch('type');

  const purgeAllCache = usePurgeAllCache(siteId!);
  const purgeSpecificPaths = usePurgeSpecificPaths(siteId!);

  const onSubmit = async (data: ClearCacheData) => {
    if (data.type === 'all') {
      await purgeAllCache.mutateAsync();
    } else {
      const splitPaths = data.paths.split(/\n|,/);
      await purgeSpecificPaths.mutateAsync({ files: splitPaths });
    }

    enqueueSnackbar(t('purge_cache_completed'), { variant: 'success' });
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    methods.reset();
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<DeleteDustbin />}
        variant="contained"
        color="tertiary"
        size="large"
        fullWidth
      >
        {t('clear_cache')}
      </Button>

      {open ? (
        <FormDialog
          form={
            <Stack gap={4}>
              <RadioField
                name="type"
                value="all"
                label={
                  <Stack gap={1} ml={3}>
                    <Typography variant="body2" fontWeight={600} color="greys.900">
                      {t('clear_all_cache')}
                    </Typography>
                    <Typography variant="body2" color="greys.500">
                      {t('cache_purge_warning')}
                    </Typography>
                  </Stack>
                }
              />
              <RadioField
                name="type"
                value="paths"
                label={
                  <Stack gap={1} ml={3}>
                    <Typography variant="body2" fontWeight={600} color="greys.900">
                      {t('clear_specific_paths')}
                    </Typography>
                    <Typography variant="body2" color="greys.500">
                      {t('clear_specific_paths_warning')}
                    </Typography>
                  </Stack>
                }
              />
              {type === 'paths' && (
                <TextField
                  name="paths"
                  label={t('enter_paths')}
                  fullWidth
                  placeholder={t('enter_paths_placeholder')}
                  multiline
                  color="primary"
                  rows="3"
                />
              )}
            </Stack>
          }
          methods={methods}
          onSubmit={onSubmit}
          onClose={handleClose}
          title={t('clear_cache')}
          submitText={t('purge')}
        />
      ) : null}
    </>
  );
};
