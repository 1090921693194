import { DialogProps } from '@mui/material';
import { useSdk } from './useSdk';

export const useDialogProps = (
  onClose: (() => void) | undefined,
  preventAccidentalClose = false
) => {
  const sdk = useSdk();

  const handleClose: DialogProps['onClose'] = (event, reason) => {
    if (reason === 'escapeKeyDown') {
      (event as any)?.preventDefault?.();
    }

    if (preventAccidentalClose) {
      return;
    }

    if (onClose) {
      onClose();
    }
  };

  return {
    hideBackdrop: Boolean(sdk),
    container: sdk ? window.document.getElementsByTagName('main')[0] : window.document.body,
    style: sdk ? { position: 'absolute' as const } : {},
    onClose: handleClose,
  };
};
